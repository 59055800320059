<template>
  <div style="padding: 0 20px">
    <div
      v-if="loading"
      class="flexWrap menuStyle"
      style="
        border-radius: 20px;
        padding: 10px;
        background-color: whiter;
        height: 160px;
      "
    >
      <!-- <van-loading
        style="margin: 0 auto; line-height: 150px"
        type="spinner"
        color="#1989fa"
        >加载中...</van-loading
      > -->
    </div>
    <div
      v-show="!loading"
      class="flexWrap menuStyle"
      style="
        border-radius: 20px;
        padding: 10px;
        background-color: whiter;
        height: 160px;
      "
    >
      <div id="schedule"></div>
    </div>
    <div
      class="menuStyle"
      style="
        padding: 0 20px;
        margin: 10px 0;
        overflow: hidden;
        border-radius: 15px;
      "
    >
      <div
        v-for="(item, index) in formList"
        :key="index"
        style="padding: 5px 0; border-bottom: 1px solid #ececec"
        v-if="ZjList.length == 0 ? item.name != '查看关联的模型选择集' : true"
        @click="ListClick(item)"
      >
        <div style="height: 40px; line-height: 20px">
          <div class="flexLR" style="height: 100%">
            <div
              class="flexLR"
              style="width: 50%; justify-content: left; height: 100%"
            >
              <div
                style="
                  height: 100%;
                  width: 100%;
                  align-items: flex-start;
                  margin-left: 10px;
                "
                class="flexcolumn"
              >
                <div class="nowrap" style="width: 90%">{{ item.name }}</div>
              </div>
            </div>
            <div v-if="item.name == '完成进度(%)'">
              <van-stepper
                :min="item.value"
                max="100"
                v-model="item.value"
                button-size="22"
              />
            </div>
            <div v-else>{{ item.value }}<van-icon name="arrow" /></div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="menuStyle"
      style="
        padding: 20px;
        margin: 10px 0;
        overflow: hidden;
        border-radius: 15px;
      "
      v-show="fileList.length > 0"
    >
      <van-uploader
        ref="upLoader"
        v-model="fileList"
        :after-read="afterRead"
        multiple
        :max-count="1"
      />
    </div>

    <!-- <div class="rich-container">
      <div class="rich-editor document-editor">
        <div id="toolbar-container" class="document-editor__toolbar" />
        <div class="document-editor__editable-container">
          <div id="editor" class="document-editor__editable"></div>
        </div>
      </div>
    </div> -->

    <van-cell
      title="拍照上传"
      @click="$refs.upLoader.chooseFile()"
      is-link
    ></van-cell>

    <van-field
      v-model="message"
      rows="3"
      autosize
      label-width="60"
      colon
      label="说明内容"
      type="textarea"
      maxlength="50"
      placeholder="请输入内容"
      show-word-limit
    />

    <div
      class="menuStyle"
      style="
        padding: 20px;
        margin: 10px 0;
        overflow: hidden;
        border-radius: 15px;
      "
    >
      <van-button type="info" style="width: 100%" @click="addRecord"
        >完成填报</van-button
      >
    </div>

    <van-popup
      position="bottom"
      :style="{ height: '50%' }"
      v-model="formList[0].show"
    >
      <van-picker
        title="工程计划"
        show-toolbar
        style="width: 100%; height: 100%"
        :columns="PlanList"
        :default-index="index"
        value-key="pjpl03"
        @confirm="onConfirmPlan"
        @cancel="formList[0].show = false"
      />
    </van-popup>
    <van-popup
      position="bottom"
      :style="{ height: '50%' }"
      v-model="formList[2].show"
    >
      <van-picker
        title="选择子集"
        show-toolbar
        style="width: 100%; height: 100%"
        :columns="ZjList"
        value-key="mosl02"
        @confirm="onConfirmZj"
        @cancel="formList[2].show = false"
      />
    </van-popup>
    <van-popup v-model="formList[1].show" round position="bottom">
      <van-cascader
        v-model="PlanName"
        title="请选择任务名称"
        :field-names="listType"
        :options="PlanNameList"
        @close="formList[1].show = false"
        @finish="PlanNameFc"
        @change="PlanNameChange"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  GetPlanList,
  GetPlanDetailTree,
  AddRecord,
  GetPlanDetailOne,
  UpdateNoiceImage
} from "@/api/index";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn";
import UploadAdapter from "./uploadAdapter.js";
export default {
  name: "schedule",
  components: {},
  data() {
    return {
      message: "",
      editor: null,
      loading: true,
      formList: [
        { name: "选择工程计划", value: "", show: false },
        { name: "选择任务名称", value: "", show: false, id: "" },
        { name: "查看关联的模型选择集", value: "", show: false },
        { name: "完成进度(%)", value: 1 },
      ],
      fileList: [],
      PlanList: [],
      ZjList: [],
      jdCount: 0.1,
      PlanNameList: [],
      PlanName: "",
      listType: {
        text: "pldt04",
        value: "pldt01",
        children: "children",
      },
      index: 0,
      modelId: "",
      imgHtml:""
    };
  },
  computed: {},
  watch: {},
  methods: {
    async afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      const data = new FormData();
      data.append("upload", file.file);
      const res =await UpdateNoiceImage(data);
      this.imgHtml=`<img src='${res.url}' width="100" height="100" />`
      file.status = "done";
      file.message = "";
    },
    initCKEditor() {
      // 初始化编辑器
      DecoupledEditor.create(document.querySelector("#editor"), {
        language: "zh-cn", // 中文
        removePlugins: ["MediaEmbed"], // 除去视频按钮
      })
        .then((editor) => {
          editor.plugins.get("FileRepository").createUploadAdapter = (
            loader
          ) => {
            return new UploadAdapter(loader);
          };
          const toolbarContainer = document.querySelector("#toolbar-container");
          toolbarContainer.appendChild(editor.ui.view.toolbar.element);
          this.editor = editor; // 将编辑器保存起来，用来随时获取编辑器中的内容等，执行一些操作
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onConfirmZj(v) {
      this.$router.push({
        name: "Xzj",
        params: { modelId: this.modelId, ...v },
      });
    },
    addRecord() {
      if (
        this.formList[1].id != "" &&
        this.formList[1].value != "" &&
        this.formList[3].value != ""
      ) {
        // var editor = document.getElementById("editor");
        let params = {
          dtlk02: this.$store.state.pid,
          dtlk03: this.formList[1].id,
          dtlk08: this.formList[3].value,
          dtlk07: this.imgHtml + `<p>${this.message}</p>`,
          dtlk09: this.$store.state.uid,
          dtlk10: new Date(),
        };
        AddRecord(params).then((res) => {
          if (res.Code > 0) {
            this.$Toast("更改项目计划进度成功");
          }
          this.cz();
          this.GetPlanList();
          this.Myecharts();
          if (this.$route.query.obj) {
            this.$router.go(-1);
          }
        });
        this.imgHtml = "";
        this.message = "";
      } else {
        this.$Toast.fail("请选择最底层的工程任务");
      }
    },
    PlanNameFc(v) {
      this.formList[1].value = [];
      this.ZjList = [];
      this.formList[1].id = "";
      let data = v.selectedOptions.filter((e) => {
        this.formList[1].value.push(e.pldt04);
      });
      this.formList[1].value = this.formList[1].value.join("/");
      this.formList[1].id = v.value;
      this.formList[1].show = false;
      this.GetPlanDetailOne();
    },
    GetPlanDetailOne() {
      GetPlanDetailOne({ id: this.formList[1].id }).then((res) => {
        this.ZjList = res.Data.selectList || [];
      });
    },
    PlanNameChange(v) {
      // this.formList[1].value = v.selectedOptions[0].pldt04;
    },
    onConfirmPlanName(v) {
      console.log(v);
    },
    onConfirmPlan(v) {
      this.ZjList = [];
      this.modelId = v.pjpl11;
      this.formList[0].value = v.pjpl03;
      this.formList[0].show = false;
      this.formList[1].value = "";
      this.jdCount = v.wcjd / 10;
      this.loading = false;
      this.PlanNameList = [];
      let _this = this;
      GetPlanDetailTree({
        params: {
          planId: v.pjpl01,
        },
      }).then((res) => {
        function dg(data, s) {
          data.forEach((v) => {
            let _s = s ? s + "-" : "";
            v["ids"] = _s + v.pldt01;
            if (v.children) {
              dg(v.children, v.ids);
            }
          });
        }
        var result = [];
        dg(res.Data);
        cx(res.Data, this.$store.state.uid);

        function cx(data, id) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (
              element.pldt12 != null &&
              element.pldt12.split(",").map(Number).includes(id)
            ) {
              result.push(element.ids);
            } else {
              if (element.children) {
                cx(element.children, id);
              }
            }
          }
        }

        fc(res.Data);
        function fc(data) {
          data.forEach((v) => {
            if (v.children.length == 0) {
              delete v.children;
            } else {
              v.children = v.children.filter((k, inx) => {
                let userids = [];
                userids = userids
                  .concat(k.pldt12 ? k.pldt12.split(",") : [])
                  .concat(k.pldt13 ? k.pldt13.split(",") : [])
                  .concat([k.pldt21])
                  .map(Number);
                return (
                  userids.includes(_this.$store.state.uid) ||
                  result.includes(k.pldt01)
                );
              });
              if (v.children.length == 0) {
                delete v.children;
              } else {
                fc(v.children);
              }
            }
          });
        }
        this.PlanNameList = res.Data.filter((v) => {
          return result.includes(v.pldt01.toString()) || v.children;
        });

        if (this.$route.query.obj) {
          this.formList[1].id = JSON.parse(
            decodeURIComponent(this.$route.query.obj)
          ).parentId;

          let list = [];
          jdcx(this.PlanNameList, this.formList[1].id);
          function jdcx(data, id) {
            data.forEach((el) => {
              if (el.pldt01 == id) {
                list.push(el);
              } else {
                if (el.children) {
                  jdcx(el.children, id);
                }
              }
            });
          }

          this.formList[3].value = list[0].CompletionSchedule || 0;

          this.formList[1].value = JSON.parse(
            decodeURIComponent(this.$route.query.obj)
          ).name;
          this.GetPlanDetailOne();
        }

        this.Myecharts();
      });
    },
    ListClick(item) {
      if (
        this.$route.query.obj &&
        item.name != "查看关联的模型选择集" &&
        item.name != "完成进度(%)"
      ) {
        this.$Toast("待办任务不允许更改");
      } else {
        item.show = true;
      }
    },
    updateData() {
      this.cz();
      this.GetPlanList();
      this.$emit("updateLoading", "进度上报");
    },
    cz(name) {
      for (const key in this.$data) {
        if (key != name) {
          this[key] = this.$options.data()[key];
        }
      }
    },
    menuBtn(item) {
      if (item.path == "") {
        this.$Toast.fail("此功能研发中...");
      } else {
        this.$router.push(item.path);
      }
    },
    async GetPlanList() {
      let res = await GetPlanList({
        pageIndex: 1,
        pageSize: 9999,
        searchword: "",
        userid: this.$store.state.uid,
        xid: this.$store.state.pid,
      });
      console.log(res);
      if (res.Data.list.length) {
        this.PlanList = res.Data.list;
        this.$store.state.PlanList = this.PlanList;
      }
      if (this.$route.query.obj) {
        let activeObj = this.PlanList.filter((el, index) => {
          if (
            el.pjpl01 ==
            JSON.parse(decodeURIComponent(this.$route.query.obj)).id
          ) {
            this.index = index;
            return true;
          }
        })[0]||[];
        activeObj.length?this.onConfirmPlan(activeObj):'';
      } else {
        this.PlanList.length?this.onConfirmPlan(this.PlanList[0]):'';
      }
      this.loading = false;
    },
    Myecharts() {
      var _this = this;
      var myChart = this.$echarts.init(document.getElementById("schedule"));
      var option = {
        angleAxis: {
          show: false,
          max: (100 * 360) / 270, //-45度到225度，二者偏移值是270度除360度
          type: "value",
          startAngle: 225, //极坐标初始角度
          splitLine: {
            show: false,
          },
        },
        barMaxWidth: 10, //圆环宽度
        radiusAxis: {
          show: false,
          type: "category",
        },
        //圆环位置和大小
        polar: {
          center: ["50%", "50%"],
          radius: "110",
        },
        series: [
          {
            type: "bar",
            data: [
              {
                //上层圆环，显示数据
                value: _this.jdCount * 10,
                itemStyle: {
                  color: {
                    //图形渐变颜色方法，四个数字分别代表，右，下，左，上，offset表示0%到100%
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1, //从左到右 0-1
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#4F6DFA",
                      },
                      {
                        offset: 1,
                        color: "#5AA6F9",
                      },
                    ],
                  },
                  shadowColor: "rgba(34, 124, 234, 0.5)",
                  shadowBlur: 10,
                },
              },
            ],
            barGap: "-100%", //柱间距离,上下两层圆环重合
            coordinateSystem: "polar",
            roundCap: true, //顶端圆角从 v4.5.0 开始支持
            z: 2, //圆环层级，同zindex
          },
          {
            //下层圆环，显示最大值
            type: "bar",
            data: [
              {
                value: 200,
                itemStyle: {
                  color: "#f3f3f3",
                },
              },
            ],
            barGap: "-100%",
            coordinateSystem: "polar",
            roundCap: true,
            z: 1,
          },
          //仪表盘
          {
            name: "AQI",
            type: "gauge",
            max: 10,
            startAngle: 225, //起始角度，同极坐标
            endAngle: -45, //终止角度，同极坐标
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            title: {
              offsetCenter: [0, 10],
              color: "#000",
              fontSize: 24,
              fontWeight: 600,
              rich: {
                a: {
                  fontWeight: "500",
                  fontSize: 12,
                  color: "#000",
                  lineHeight: 40,
                },
              },
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: _this.jdCount * 10,
                name:
                  (_this.jdCount * 10).toFixed(1) +
                  "%" +
                  "\n" +
                  "{a|" +
                  "实际进度" +
                  "}",
              },
            ],
          },
          {
            type: "pie",
            radius: ["80%", "69%"],
            hoverAnimation: false,
            startAngle: 225,
            endAngle: 0,
            data: [
              {
                name: "",
                value: _this.jdCount / 10,
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                itemStyle: {
                  color: "rgba(255,255,255,0)",
                },
              },
              {
                //画中间的图标
                name: "",
                value: 0,
                label: {
                  position: "inside",
                  backgroundColor: "#fff",
                  borderRadius: 6,
                  padding: 3,
                  borderWidth: 0,
                  borderColor: "#fff",
                },
              },
              {
                name: "",
                value: 1.33 - _this.jdCount / 10,
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                itemStyle: {
                  color: "rgba(255,255,255,0)",
                },
              },
            ],
          },
        ],
      };

      myChart.setOption(option);
    },
  },
  created() {},
  mounted() {
    this.GetPlanList();
    // this.initCKEditor();
  },
};
</script>
<style lang='scss' scoped>
#schedule {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
/deep/.document-editor__editable-container {
  background-color: white;
}
</style>