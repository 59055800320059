import { UpdateNoiceImage } from '@/api'
export default class UploadAdapter {
    constructor(loader) {
        this.loader = loader
    }
    upload() {
        return this.loader.file.then(file => new Promise((resolve, reject) => {
            const data = new FormData()
            data.append('upload', file)
            UpdateNoiceImage(data).then(res => {
                // if (res.data.meta.code === 200) {
                //     const url = res.data.url // 后台返回的上传成功后的图片地址
                resolve({
                    default: res.url
                })
                // }
            }).catch(error => {
                reject(error)
            })
        }))
    }
    abort() {
    }
}


